import { Props as BadgeWidgetOptions } from "./components/PopupWidget/PopupWidget";
import initializeCalendly from "./calendly-widget";

import { InlineWidgetOptions } from "./components/InlineWidget/InlineWidget";
import { PopupWidgetOptions } from "./components/PopupButton/PopupButton";

export interface ICalendly {
  initInlineWidget(options: InlineWidgetOptions): void;
  showPopupWidget(url: string): void;
  closePopupWidget(): void;
  destroyBadgeWidget(): void;
  initBadgeWidget(options: BadgeWidgetOptions): void;
  initPopupWidget(options: PopupWidgetOptions): void;
}

declare global {
  interface Window {
    Calendly: ICalendly;
  }
}

type Optional<T extends object> = {
  [P in keyof T]?: T[P];
};

export type Prefill = Optional<{
  name: string;
  email: string;
  firstName: string;
  lastName: string;
  location: string;
  guests: string[];
  customAnswers: Optional<{
    a1: string;
    a2: string;
    a3: string;
    a4: string;
    a5: string;
    a6: string;
    a7: string;
    a8: string;
    a9: string;
    a10: string;
  }>;
}>;

export enum CalendlyEvent {
  PROFILE_PAGE_VIEWED = "calendly.profile_page_viewed",
  EVENT_TYPE_VIEWED = "calendly.event_type_viewed",
  DATE_AND_TIME_SELECTED = "calendly.date_and_time_selected",
  EVENT_SCHEDULED = "calendly.event_scheduled",
}

export type Utm = Optional<{
  utmCampaign: string;
  utmSource: string;
  utmMedium: string;
  utmContent: string;
  utmTerm: string;
}>;

export type PageSettings = Optional<{
  /**
   * @description Use this setting to hide your profile picture, name, event duration, location, and description when Calendly is embedded. This will help reduce duplicate information that you may already have on your web page.
   * @see {@link https://help.calendly.com/hc/en-us/articles/360020052833-Advanced-embed-options#2} for further information.
   */
  hideLandingPageDetails: boolean;
  /**
   * @description Use this setting to hide your profile picture, name, event duration, location, and description when Calendly is embedded. This will help reduce duplicate information that you may already have on your web page.
   * @see {@link https://help.calendly.com/hc/en-us/articles/360020052833-Advanced-embed-options#2} for further information.
   */
  hideEventTypeDetails: boolean;
  /**
   * @description This setting is only available for Calendly users on the Pro plan. Use this setting to change your Calendly scheduling page's background color.
   * @example 00a2ff
   * @see {@link https://help.calendly.com/hc/en-us/articles/223147027-Embed-options-overview#3} for further information.
   */
  backgroundColor: string;
  /**
   * @description This setting is only available for Calendly users on the Pro plan. Use this setting to change your Calendly scheduling page's text color.
   * @example ffffff
   * @see {@link https://help.calendly.com/hc/en-us/articles/223147027-Embed-options-overview#3} for further information.
   */
  textColor: string;
  /**
   * @description This setting is only available for Calendly users on the Pro plan. Use this setting to change your Calendly scheduling page's primary color.
   * @example 4d5055
   * @see {@link https://help.calendly.com/hc/en-us/articles/223147027-Embed-options-overview#3} for further information.
   */
  primaryColor: string;
  /**
   * @description The General Data Protection Regulation governs data protection in the EU and EEA. Certain Calendly integrations require access to cookies with user information. If you do not embed the GDPR banner, users in those areas will not have the ability to give their consent in order to access integrations such as Google Analytics, Facebook Pixel, PayPal, and Stripe.
   * @see {@link https://help.calendly.com/hc/en-us/articles/360007385493-Cookie-FAQs} for further information.
   */
  hideGdprBanner: boolean;
}>;

export const loadScript = () => {
  if (!window.Calendly) {
    initializeCalendly();
  }
};

export const withPageSettings = (url: string, pageSettings?: PageSettings) => {
  if (!pageSettings) return url;

  const {
    backgroundColor,
    hideEventTypeDetails,
    hideLandingPageDetails,
    primaryColor,
    textColor,
    hideGdprBanner,
  } = pageSettings;

  const queryStringIndex = url.indexOf("?");
  const hasQueryString = queryStringIndex > -1;
  const queryString = url.slice(queryStringIndex + 1);
  const baseUrl = hasQueryString ? url.slice(0, queryStringIndex) : url;

  const updatedQueryString = [
    queryString,
    backgroundColor ? `background_color=${backgroundColor}` : null,
    hideEventTypeDetails ? `hide_event_type_details=1` : null,
    hideLandingPageDetails ? `hide_landing_page_details=1` : null,
    primaryColor ? `primary_color=${primaryColor}` : null,
    textColor ? `text_color=${textColor}` : null,
    hideGdprBanner ? `hide_gdpr_banner=1` : null,
  ]
    .filter((item) => item !== null)
    .join("&");

  return `${baseUrl}?${updatedQueryString}`;
};

export const openPopupWidget = (
  options: PopupWidgetOptions & { pageSettings?: PageSettings }
) => {
  loadScript();

  const widgetOptions: PopupWidgetOptions = {
    url: withPageSettings(options.url, options.pageSettings),
    prefill: options.prefill,
    utm: options.utm,
  };

  window.Calendly.initPopupWidget(widgetOptions);
};

export const closePopupWidget = () => {
  loadScript();

  window.Calendly.closePopupWidget();
};
